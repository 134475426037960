import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { selector as userSelector } from 'redux/modules/user';
import Icon from 'components/icon/index';
import Button from 'components/button/button';
import { userHavePlayerIdAndFeatureMask } from 'selectors/user/user-features-selector';

import { LITE, NAHL, PREMIUM, productList, REPORT, SCOUT } from './products';
import { selector as productSelector, actions } from './product-module';

const ProductLoadingFallback = () => <Icon type="spinner" pulse />;

const redirectToProductSite = product => {
    window.location = product ? `/` : 'https://www.theprospectexchange.com';
};

class ProductForbiddenFallback extends Component {
    render() {
        return (
            <div
                style={{
                    padding: '48px 24px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '12px'
                }}
            >
                <strong>RESTRICT ACCESS</strong>
                <Button onClick={() => redirectToProductSite(this.props.product)}>TRY AGAIN?</Button>
                <p>Support:</p>
                <a href="mailto:tpeteamsupport@theprospectexchange.com">tpeteamsupport@theprospectexchange.com</a>
            </div>
        );
    }
}

const userHasAccess = (user, product, hasFeatureMask) => {
    if (product === SCOUT && hasFeatureMask) {
        return false;
    }

    return (
        [SCOUT, REPORT, NAHL].includes(product) ||
        (user?.availableProducts && user?.availableProducts.includes(product)) ||
        ((product === PREMIUM || product === LITE) &&
            user?.availableProducts &&
            (user?.availableProducts.includes(LITE) || user?.availableProducts.includes(PREMIUM)))
    );
};

function mapStateToProps(state, props) {
    const user = userSelector(state);
    const userHaveSomeFeatureAndPlayerId = userHavePlayerIdAndFeatureMask(state);

    return {
        storeProduct: productSelector(state),
        userHasAccess: userHasAccess(user, props.product, userHaveSomeFeatureAndPlayerId),
        availableProducts: user?.availableProducts || []
    };
}

class ProductInitializer extends Component {
    static propTypes = {
        availableProducts: PropTypes.array,
        product: PropTypes.oneOf(productList).isRequired,
        storeProduct: PropTypes.oneOf(productList),
        initializeProduct: PropTypes.func.isRequired,
        userHasAccess: PropTypes.bool.isRequired
    };
    state = {
        processing: false
    };

    isProductInitialized() {
        return this.props.product === this.props.storeProduct;
    }

    handleInitialization() {
        if (!this.state.processing && !this.isProductInitialized()) {
            this.setState(
                {
                    processing: true
                },
                () => this.props.initializeProduct(this.props.product)
            );
        } else if (this.state.processing && this.isProductInitialized()) {
            this.setState({
                processing: false
            });
        }
    }

    componentDidUpdate() {
        this.handleInitialization();
    }

    componentDidMount() {
        this.handleInitialization();
    }

    render() {
        if (!this.props.userHasAccess) {
            return (
                <ProductForbiddenFallback
                    product={
                        (this.props.availableProducts || []).includes(this.props.product)
                            ? this.props.product
                            : this.props.availableProducts && this.props.availableProducts[0]
                            ? this.props.availableProducts[0]
                            : null
                    }
                />
            );
        }

        if (!this.isProductInitialized()) {
            return <ProductLoadingFallback product={this.props.product} />;
        }

        return <Fragment>{this.props.children}</Fragment>;
    }
}

const ConnectedProductInitializer = connect(
    mapStateToProps,
    actions
)(ProductInitializer);

export default ConnectedProductInitializer;
